import { DEFAULT_LANGUAGE_MAP, DEFAULT_THEME_VARIABLES, THEME_MODE } from "../../configs/constants"
import { getUrlParams } from "../../configs/utils"

const params = getUrlParams()

const initialState = {
  loadError: false,
  loading: false,
  watermark: true,
  branding: {},
  deviceData: {},
  mobileView: params.mobileView === "true",
  fullScreenBot: params.fullScreenBot === "true",
  themeMode: THEME_MODE.LIGHT,
  botName: "BOT",
  botPosition: 'right',
  primaryColor: DEFAULT_THEME_VARIABLES.PRIMARY_COLOR,
  secondaryColor: DEFAULT_THEME_VARIABLES.SECONDARY_COLOR,
  avatarUrl: "",
  triggerUrl: DEFAULT_THEME_VARIABLES.TRIGGER_URL,
  triggerSize: DEFAULT_THEME_VARIABLES.TRIGGER_SIZE,
  triggerShape: DEFAULT_THEME_VARIABLES.TRIGGER_SHAPE,
  bubbleShape: DEFAULT_THEME_VARIABLES.BUBBLE_SHAPE,
  headerHeight: DEFAULT_THEME_VARIABLES.HEADER_HEIGHT,
  fontSize: DEFAULT_THEME_VARIABLES.FONT_SIZE,
  direction: DEFAULT_THEME_VARIABLES.DIRECTION,
  liveChatEnabled: false,
  language: "en",
  languageTemplate: DEFAULT_LANGUAGE_MAP,
  showFeedback: false,
  userLoginAttributes :  {},
  homeHeading: undefined,
}

export default initialState
