import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getChatHistory } from '../../../../../data/store/botSlice';
import dayjs from 'dayjs'
import OriButton from '../../../../components/Custom/OriButton';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../../data/configs/constants';
import { cn } from '../../../../../data/configs/utils';
import { NavContext } from '..';
import ChatHistoryTile from '../../../../components/ChatHistoryTile';
import useChatbotActions from '../../../../../data/hooks/useChatBotActions';
import useLanguage from '../../../../../data/hooks/useLanguage';

const PAGE_LIMIT = 10

const ChatHistoryPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tenantId = useSelector((state) => state.botDetails.tenantId)
    const psid = useSelector((state) => state.botDetails.psid)
    const chatHistoryData = useSelector((state) => state.botDetails.chatHistoryData)
    const noChatsFoundText = useLanguage("botUiTemplate.pages.chatHistory.noChats")
    const chatHistoryTitle = useLanguage("botUiTemplate.pages.chatHistory.title")
    const [page, setPage] = useState(1)
    const [loadingChats, setLoadingChats] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const scroller = useRef(null)
    const [scrolled, setScrolled] = useState(false)
    const { setNavState } = useContext(NavContext)
    const { handleStartNewConversation } = useChatbotActions()

    const conversationStartButtonText = useLanguage("botUiTemplate.conversationStartButton")

    // Setting navigation state
    useEffect(()=>{
        setNavState({
            selectedIndex: 1,
          })
    },[setNavState])

    useEffect(() => {
        setLoadingChats(true)
        dispatch(getChatHistory({
            tenantId,
            psid,
            page: page,
            limit: PAGE_LIMIT,
            timezone: "Asia/Calcutta",
            "filters": {
                "startDate": dayjs().subtract(3, 'months').toISOString(),
                "endDate": dayjs().toISOString()
            },
        })).unwrap().then((res) => {
            if (res.data.chatSessions && res.data.chatSessions.length < PAGE_LIMIT) {
                setHasMore(false)
            }
        }).finally(() => {
            setLoadingChats(false)
        })
    }, [dispatch, tenantId, psid, page])

    const dateToChatHistoryMapping = useMemo(()=>{
        let map = {}
        chatHistoryData?.chatSessions?.forEach((item, index) => {
            const time = dayjs(item.lastBotMessageTimeStamp).format('DD MMM YYYY')
            if (!map[time]) {
                map[time] = []
            }
            map[time].push(item)
        })
        return map
    },[chatHistoryData])

    useEffect(()=>{
        scroller.current.addEventListener('scroll', (e) => {
            if(scroller.current.scrollTop === 0) {
                setScrolled(false)
            } else {
                setScrolled(true)
            }
        })
    },[])

    return (
        <div className={cn('flex flex-col h-full')}>
            <p className={cn('text-xl font-semibold text-primaryContrast text-center my-0 p-4')}>{chatHistoryTitle}</p>
            {Object.keys(dateToChatHistoryMapping).length === 0 && (
                <div className='flex flex-col gap-2 items-center justify-center flex-grow h-full'>
                    <p className='text-lg text-primaryContrast font-bold'>{noChatsFoundText}</p>
                </div>
            )}
            <div ref={scroller} className={cn('flex-grow flex flex-col gap-2 overflow-y-auto w-full px-4 rounded-3xl pb-24 mb-2')}>
                {Object.keys(dateToChatHistoryMapping).map((date, dateIndex) => (
                    <div key={dateIndex}>
                        {renderTimeStamp(date)}
                        <div className='flex flex-col gap-2'>
                            {dateToChatHistoryMapping[date].map((chat, chatIndex) => (
                                <ChatHistoryTile key={chatIndex} chat={chat} isLatest={dateIndex + chatIndex === 0}/>
                            ))}
                        </div>
                    </div>
                ))}
                {hasMore && (
                    <div className='flex justify-center my-4'>
                        <OriButton size='small' loading={loadingChats} onClick={() => {
                            setPage(prev => prev + 1)
                        }}>
                            {loadingChats ? 'Loading' : 'Load'} more chats
                        </OriButton>
                    </div>
                )}
            </div>
            <div className='mx-4 mb-20'>
                <div
                    onClick={handleStartNewConversation}
                    className='p-3 px-5 cursor-pointer flex justify-between bg-primary text-lg text-primaryContrast rounded-3xl w-full hover:bg-secondary hover:text-secondaryContrast transition-all duration-500'>
                        <p className='font-bold'> {conversationStartButtonText} </p>
                        <p> <i class="ri-send-plane-2-line"></i> </p>
                </div>
            </div>
        </div>
    )

    function renderTimeStamp(data) {
        return <div className='flex items-center my-2'>
            <div className='flex-grow h-[1px] bg-primary'/>
            <div className='p-1 px-3 rounded-full border border-primary text-primary text-xs font-medium bg-background'>{data}</div>
            <div className='flex-grow h-[1px] bg-primary'/>
        </div>
    }
}


export default ChatHistoryPage